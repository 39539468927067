import React from "react"
//import { Link } from "gatsby"
import ArticleCard from './articleCard'
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../components/layout";
import { useStaticQuery, graphql } from "gatsby"
import {Typography} from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    fontFamily:"Arial Black"
    },
}));

export default ()=>{
    const classes = useStyles(); 
    const data = useStaticQuery(graphql`query {
        allRestApiApiBlogpostsShowall {
          edges {
            node {
              data {
                blogposts {
                  id
                  body
                  createdAt(formatString: "LL", locale: "es-US")
                  img
                  title
                  updatedAt
                  url
                }
              }
            }
          }
        }
      }`)
      console.log(data.allRestApiApiBlogpostsShowall.edges[0].node.data.blogposts)
    return (
        <Layout>
           <Typography className={classes.margin} variant="h5">Articulos</Typography>
        {
            data.allRestApiApiBlogpostsShowall.edges[0].node.data.blogposts.map(articleData=>{
                return (
                    <ArticleCard state= {{articleData}}/>
                )
            }
            )
        }
        </Layout>
    )

}
